<template>
  <ul class="partner">
    <li class="morebtn">
      <template v-if="level > 0 && reqData.partnerLevel != ''" >
        <a @click="getMore" v-if="Number(data.cntBotPartner)" class="plus tablemg">
          <template v-if="level">
            <i class="fa fa-plus" :class="{'fa-times': inSearchList.length > 0}" aria-hidden="true"></i>
          </template>
        </a>
        <template v-else>
           <span class="else"><i class="fa">•</i></span>
        </template>
      </template>
    <a @click="detailOpen('partner', data.memId)" class="pid">{{data.memId}}</a>
    </li>
    <li class="morebtn">
      <a @click="detailOpen('partner', data.memId)" :class="data.partnerLevel">{{data.memNick}}</a>
    </li>
    <li :class="data.partnerLevel"><span class="box">{{data.levelName}}</span></li>
    <li class="mw130">
      <button type="button" class="btn-layout btn-range btn-min" @click="onInputRate(data, 'pointRate', 'minPointRate')">{{ $t('table.head.min') }}</button>
      <input type="text" class="w50" name="" v-model="pointRate">
      <button type="button" class="btn-layout btn-range btn-max" @click="onInputRate(data, 'pointRate', 'maxPointRate')">{{ $t('table.head.max') }}</button>
    </li>
    <li class="mw130">
      <button type="button" class="btn-layout btn-range btn-min" @click="onInputRate(data, 'loseRate', 'minPointRate')">{{ $t('table.head.min') }}</button>
      <input type="text" class="w50" name="" v-model="loseRate">
      <button type="button" class="btn-layout btn-range btn-max" @click="onInputRate(data, 'loseRate', 'maxPointRate')">{{ $t('table.head.max') }}</button>
    </li>
    <li>{{ numberWithCommas(data.cntBotPartner) }}</li>
    <li>{{ numberWithCommas(data.cntBotMem) }}</li>
    <li>{{ numberWithCommas(data.cashAmt) }}</li>
    <li>{{ numberWithCommas(data.pointAmt) }}</li>
    <li><button type="button" class="btn-layout btn-skybl mr-0" v-if="data.partnerLevel !== 'PTN_5'" @click="goToPageNameOpen('partnerCreate', data)">{{$t('table.body.move')}}</button></li>
    <li><button type="button" class="btn-layout btn-coB mr-0" @click="goToPageName('userCreate', data)">{{$t('table.body.move')}}</button></li>
    <li><button type="button" class="btn-layout btn-darkY mr-0" @click="onChangeRate(data)">{{$t('common.save')}}</button></li>
  </ul>
  <ul v-if="inSearchList.length > 0" class="partner">
    <li>
      <component :is="component" v-if="inSearchList.length > 0" :key="data.memId"
                 :table="'more'"
                 :list="inSearchList"
                 :reqData="reqData"
                 :level="this.level + 1"
      />
    </li>
  </ul>

  <div class="modalWrap" v-if="modalActive">
    <div class="modal-item modal2">
      <article class="modal-title">
        <h2>{{ $t('table.head.subpartner') }}</h2>
      </article>
      <div class="modal-body">
        <article class="fx-item-auto fx gap-5">
          <h4>{{$t('common.partnerInfo')}}</h4>
          <table class="part">
            <colgroup>
              <col width="20%"/>
              <col width="30%"/>
              <col width="20%"/>
              <col width="30%"/>
            </colgroup>
            <tr>
              <th>{{ $t('table.head.id') }}</th>
              <td><input class="in" name="newMemId" type="text"/></td>
              <th>{{$t('company.pw')}}</th>
              <td><input class="in" name="newMemPass" type="password"/></td>
            </tr>
            <tr>
              <th>{{ $t('table.head.nick') }}</th>
              <td><input class="in" type="text"/></td>
              <th>{{$t('company.cashPass')}}</th>
              <td><input class="in" type="password"/></td>
            </tr>
            <tr>
              <th>{{ $t('table.head.partnerLevel') }}</th>
              <td></td>
              <th>{{$t('company.bankAccount')}}</th>
              <td class="accountInfo">
                <select>
                  <option>BANK1</option>
                  <option>BANK2</option>
                  <option>BANK3</option>
                  <option>BANK4</option>
                </select>
                <input class="in" type="text"/>
              </td>
            </tr>
            <tr>
              <th>{{ $t('table.head.memName') }}</th>
              <td><input class="in" type="text"/></td>
              <th>{{ $t('table.head.memPhone') }}</th>
              <td><input class="in" type="text"/></td>
            </tr>
          </table>
          <h4>{{ $t('table.head.gRateSet') }}</h4>
          <table class="part part2">
            <thead>
              <tr>
                <th colspan="2">{{ $t('common.rolling') }}(%)</th>
                <th colspan="2">{{ $t('common.losing') }}(%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t('table.head.casino') }}</td>
                <td>{{ $t('table.head.slot') }}</td>
                <td>{{ $t('table.head.casino') }}</td>
                <td>{{ $t('table.head.slot') }}</td>
              </tr>
              <tr>
                <td>{{ $t('common.maxvalue') }} : 0.00<br>{{ $t('common.minvalue') }} : 0.00</td>
                <td>{{ $t('common.maxvalue') }} : 0.00<br>{{ $t('common.minvalue') }} : 0.00</td>
                <td>{{ $t('common.maxvalue') }} : 0.00<br>{{ $t('common.minvalue') }} : 0.00</td>
                <td>{{ $t('common.maxvalue') }} : 0.00<br>{{ $t('common.minvalue') }} : 0.00</td>
              </tr>
              <tr>
                <td><input></td>
                <td><input></td>
                <td><input></td>
                <td><input></td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
      <div class="btn-wrap">
        <button class="btn-layout btn-blue" type="button">{{ $t('button.save') }}</button>
        <button class="btn-layout btn-blue" type="button" @click="modalClose">{{ $t('button.close') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'
import { partnerListRateChange, partnerNewSettingList, partnerNewListRateChange } from '@/api/partner'

export default {
  components: { },
  name: 'partnerTr',
  inheritAttrs: false,
  props: [
    'data',
    'reqData',
    'level',
    'isCode'
  ],
  computed: {
    ...mapState([
      'gameGroupList',
      'siteData'
    ]),
    component () {
      return defineAsyncComponent(() => import('@/components/ui/partner/partnerTableSet.vue'))
    }
  },
  mounted () {
  },
  data () {
    return {
      more: false,
      inSearchList: [],
      loader: false,
      modalActive: false,
      pointRate: '0',
      loseRate: '0'
    }
  },
  created () {
    this.pointRate = JSON.parse(JSON.stringify(this.data.pointRate))
    this.loseRate = JSON.parse(JSON.stringify(this.data.loseRate))
  },
  watch: {
    data () {
      this.inSearchList = []
      this.more = false
      this.loader = false
    }
  },
  methods: {
    onInputRate (item, key, targetKey) {
      this[key] = item[targetKey]
    },
    onRateCheck (item) {
      let isError = false
      const pointRate = this.pointRate
      const loseRate = this.loseRate
      if (pointRate > item.maxPointRate) {
        alert('입력한 롤링이 최대값 보다 큽니다.')
        isError = true
      }

      if (pointRate < item.minPointRate) {
        alert('입력한 롤링이 최소값 보다 작습니다.')
        isError = true
      }

      if (loseRate > item.maxLoseRate) {
        alert('입력한 루징이 최대값 보다 큽니다.')
        isError = true
      }

      if (loseRate < item.minLoseRate) {
        alert('입력한 루징이 최소값 보다 작습니다.')
        isError = true
      }

      return !isError
    },
    async onChangeRate (item) {
      const isCheckSuccess = this.onRateCheck(item)

      if (isCheckSuccess) {
        if (confirm('요율을 수정하시겠습니까?')) {
          this.emitter.emit('Loading', true)
          const params = {
            siteId: this.siteData.siteId,
            memId: item.memId,
            gameCategory: this.reqData.gameCategory,
            vendorCode: this.reqData.vendorCode,
            rateTypeCode: this.isCode ? this.reqData.rateTypeCode : '',
            pointRate: Number(this.pointRate),
            loseRate: Number(this.loseRate)
          }

          await partnerNewListRateChange(params).then(result => {
            if (result.resultCode === '0') {
              alert('요율 수정이 완료되었습니다.')
            } else {
              alert('요율 수정이 실패되었습니다. 잠시 후 다시 시도해주세요.')
            }
            this.emitter.emit('Loading', false)
          })
        }
      } else {
        return false
      }
    },
    goToPageName (name, item) {
      this.$router.push({ name, query: { partnerLevel: item.partnerLevel, partnerId: item.memId } })
    },
    goToPageNameOpen (name, item) {
      const routeData = this.$router.resolve({ name, query: { partnerLevel: item.partnerLevel, partnerId: item.memId } })
      window.open(routeData.href, '_blank')
    },
    async getMore () {
      this.emitter.emit('Loading', true)
      if (this.inSearchList.length === 0) {
        const reqData = { ...this.reqData }
        const memId = this.data.memId
        reqData.searchType = 'INNER'
        reqData.memId = memId
        reqData.page = 1
        const res = await partnerNewSettingList(reqData)
        if (res.resultCode === '0') {
          this.inSearchList = res.data.list

          setTimeout(function () {
            this.loader = true
          }.bind(this), 500)
        }
      } else {
        this.inSearchList = []
      }
      this.emitter.emit('Loading', false)
    },
    modalOpen () {
      this.modalActive = true
    },
    modalClose () {
      this.modalActive = false
    }
  }
}
</script>

<style scoped>
.p0 {padding: 0;}
.w70 {width: 70px;}
.w50 {width: 50px;}
.w30 {width: 30px;}
.btn-stairOpen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonPositionWrap {
  display: flex;
  justify-content: center;
}
.searchBar > * {
  margin-right: 5px;
}
.searchBar > *:last-child {
  margin-right: 0;
}
.memIdRow {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
}
.btn-childOpen {
  position: relative;
}
.memIdRow span {
  position: relative;
  white-space: nowrap;
}
.moreTable {width: 100%;margin-top: -5px;}
.moreTable .moreTable {border: 0;}
.mw130 {min-width: 130px;width: 100%; padding-left: 5px; padding-right: 5px;}
.pid {padding-left: 20px;background: #fff;height: 40px;min-width: 130px;width: 100%;border: 1px solid #e1e0e0;border-right: 0;display: flex;align-items: center;}
.partner .partner .pid {min-width: 146px;}
.partner .partner .partner .pid {min-width: 126px;}
.partner .partner .partner .partner .pid {min-width: 106px;}
.partner .partner .partner .partner .partner .pid {min-width: 86px;}
/*.partner > li {width: calc(100% - 10px);}
.partner li.morebtn:first-child {width: 200px;}*/

/*.partner .partner li {background: #e8ffe9 !important;}
.partner .partner .partner li {background: #d8eaf5 !important;}
.partner .partner .partner .partner li {background: #fffee1 !important;}
.partner .partner .partner .partner .partner li {background: #e1fffb !important;}
.partner .partner .partner .partner .partner .partner li {background: #ffe1e1 !important;}*/

/* .partner li:first-child {justify-content: left;}
.partner li:first-child .morebtn > a:first-child,
.partner li:first-child .morebtn .else {margin-left: 20px;}
.partner .partner li:first-child .morebtn > a:first-child,
.partner .partner li:first-child .morebtn .else {margin-left: 40px;}
.partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner li:first-child .morebtn .else {margin-left: 60px;}
.partner .partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 80px;}
.partner .partner .partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 100px;}
.partner .partner .partner .partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 120px;} */
.partner li:first-child {justify-content: left;}
.partner li:first-child .morebtn > a.tablemg,
.partner li:first-child .morebtn .else {margin-left: 20px;}
.partner .partner li:first-child .morebtn > a.tablemg,
.partner .partner li:first-child .morebtn .else {margin-left: 40px;}
.partner .partner .partner li:first-child .morebtn > a.tablemg,
.partner .partner .partner li:first-child .morebtn .else {margin-left: 60px;}
.partner .partner .partner .partner li:first-child .morebtn > a.tablemg,
.partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 80px;}
.partner .partner .partner .partner .partner li:first-child .morebtn > a.tablemg,
.partner .partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 100px;}
.partner .partner .partner .partner .partner .partner li:first-child .morebtn > a.tablemg,
.partner .partner .partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 120px;}


.mainTable tbody:last-child .moreTable tr:last-child {border-bottom: 0;}
.morebtn {display: flex;align-items: center;}
.morebtn a {cursor: pointer;}
.morebtn a:first-child {justify-content: center;}
.morebtn a:first-child,
.morebtn .else {padding-right: 13px;height: 40px;display: flex;align-items: center;}
.morebtn a .fa,
.else .fa {color: #a7a7a7;border: 2px solid #a7a7a7;border-radius: 4px;padding: 0;width: 12px;height: 12px;display: flex;align-items: center;justify-content: center;font-size: 12px;}
td a {cursor: pointer;display: flex;align-items: center;justify-content: center;color: #0a877b;}

.partner {display: flex;margin-top: 5px;}
.partner li {width: 100%;min-height: 40px;background: #fff;font-size: 14px;display: flex;align-items: center;justify-content: center;border-top: solid 1px #e1e0e0;border-bottom: solid 1px #e1e0e0;}
.partner li:first-child {background: none;border: 0;}
.partner li:nth-child(12) {border-right: solid 1px #e1e0e0;}
.partner .morebtn {position: relative;}
/*.partner .morebtn::before {
  content: '';
  width: 1px;
  height: 47px;
  background: #dbbdbd;
  position: absolute;
  left: 20px;
  top: 0;
}
.partner .partner .morebtn::before {left: 30px;}
.partner .partner .partner .morebtn::before {left: 40px;}
.partner .partner .partner .partner .morebtn::before {left: 50px;}
.partner .partner .partner .partner .partner .morebtn::before {left: 60px;}*/
.partner li:first-child .plus,
.partner li:first-child .morebtn .else {position: relative;}
.partner li:first-child .plus:first-child::before,
.partner li:first-child .else::before {
   content: '';
   width: 1px;
   height: 48px;
   background: #e1e0e0;
   position: absolute;
   left: 20px;
   top: 0;
}
.partner li:first-child .plus:first-child::after,
.partner li:first-child .else::after {
  content: '';
  width: 10px;
  height: 1px;
  background: #e1e0e0;
  position: absolute;
  left: 20px;
  top: 50%;
}

.NORMAL {color: #b53f3f;}
.PTN_1 {color: #b51d44;}
.PTN_2 {color: #a300ab;}
.PTN_3 {color: #5b39bc;}
.PTN_4 {color: #754b23;}
.PTN_5 {color: #047972;}
.box {width: 45px;}
.NORMAL .box {background: #6d409d;color: #fff;padding: 2px 4px;border-radius: 3px;}
.PTN_1 .box {background: #021024;color: #fff;padding: 2px 4px;border-radius: 3px;}
.PTN_2 .box {background: #052659;color: #fff;padding: 2px 4px;border-radius: 3px;}
.PTN_3 .box {background: #1a5999;color: #fff;padding: 2px 4px;border-radius: 3px;}
.PTN_4 .box {background: #80AEC9;color: #fff;padding: 2px 4px;border-radius: 3px;}
.PTN_5 .box {background: #eb7a3f;color: #fff;padding: 2px 4px;border-radius: 3px;}

.modal-title {
  border-bottom: 2px solid #e1534e;
  padding: 5px 0;
  font-size: 18px;
  color: #adadad;
}

.modal-title .PTtabmenu {
  justify-content: center;
}

.modal-title .PTtabmenu li.current {
  font-weight: bold;
  box-sizing: border-box;
  color: #fff;
}

.sub-title {
  color: #fff;
  font-size: 18px;
  border-bottom: 0;
  justify-content: center;
}

.modal-item {
  width: 70%;
  margin: 0 auto;
  position: relative;
  margin-top: 350px;
  background: #353535;
  color: #fff;
  padding-bottom: 20px;
}
.modal-item h2 {
  font-size: 25px;
  padding: 7px 0;
  color: #fff;
  font-weight: bold
}
.modal-close {
  transform: translate(-50%, 100%);
}
.modal-item .btn-layout {
  padding: 8px 15px;
  font-size: 16px
}
.ui-check-wrap {
  width: fit-content;
  margin-right: 5px;
}
.btn-wrap {
  gap: 10px;
  width: 100%;
}
.modalWrap .item-title {
  padding: 5px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  min-width: 130px;
}

.modalWrap .item-title + input {
  text-align: right;
}

.modalWrap .fx-item-auto {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.modalWrap .item-title::before {
  font-size: 10px;
  content: "●";
  color: #e1534e;
  margin-right: 7px;
}

.modal-body {
  padding: 20px 60px 10px;
}

.modal-body input {
  background: #353535;
  border: 1px solid #5a5a5a;
  color: #fff;
  font-size: 18px;
  height: auto;
  padding: 5px 15px;
  box-sizing: border-box;
  width: 100%;
}

.modal-body input[type="text"]:disabled {
  border: 0;
}

.modal-body select {
  background: #353535;
  border: 1px solid #5a5a5a;
  color: #fff;
  font-size: 16px;
  height: auto;
  padding: 5px 15px;
  width: 49%;
}

.modalNotice {
  color: red;
  font-size: 0.8em;
  font-weight: 700;
}
.modal-body .accountInfo {display: flex; gap: 1%;}
.modal-body .accountInfo input { width: 50%; }
.part{
  width: 100%;
  margin-bottom: 20px;
}
.modal-body h4 {font-size: 24px;}
.part td{
  background: none;
  border: 0;
  color: #fff;
}
.part td, .part th {
  border: 1px solid #505050;
  padding: 10px 20px;
}
.part2 input {width: 30%;}
.btn-layout {
  border-radius: 3px;
  padding: 3px 7px;
}
.btn-min {
  border-radius: 3px 0 0 3px;
  margin-right: 5px;
}
.btn-max {
  border-radius: 0 3px 3px 0;
  margin-left: 5px;
}
.btn-range {
  background: #6f7172;
}

</style>
