<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
        <div class="box-ui-check searchbar-content">
             <div class="title"><span>{{ $t('common.searchOp') }}</span></div>
             <div>
              <select class="mr-5" v-model="searchOptionText.option">
                 <option value="memId">{{ $t('searchArea.id') }}</option>
                 <option value="memNick">{{ $t('searchArea.nick') }}</option>
               </select>
               <input type="text" v-model="searchOptionText.text" @keydown.enter="loadData(1)"/>
               <button class="btn-search ml-5" type="button" @click="loadData(1)">
                 <i class="fas fa-search"></i>
               </button>
             </div>
           </div>
           
           <div class="search-selector">
             <button type="button" :class="{'select-btn': reqData.partnerLevel === ''}" @click="reqData.partnerLevel = ''" class="" >{{ $t('common.all') }}</button>
             <button type="button" :class="{'select-btn': reqData.partnerLevel === 'PTN_1'}" @click="reqData.partnerLevel = 'PTN_1'" class="" >{{ $t('common.seniorA') }}</button>
             <button type="button" :class="{'select-btn': reqData.partnerLevel === 'PTN_2'}" @click="reqData.partnerLevel = 'PTN_2'" class="" >{{ $t('common.masterA') }}</button>
             <button type="button" :class="{'select-btn': reqData.partnerLevel === 'PTN_3'}" @click="reqData.partnerLevel = 'PTN_3'" class="" >{{ $t('common.juniorA') }}</button>
             <button type="button" :class="{'select-btn': reqData.partnerLevel === 'PTN_4'}" @click="reqData.partnerLevel = 'PTN_4'" class="" >{{ $t('common.agentA') }}</button>
             <button type="button" :class="{'select-btn': reqData.partnerLevel === 'PTN_5'}" @click="reqData.partnerLevel = 'PTN_5'" class="" >{{ $t('common.companyA') }}</button>
           </div>
       </div>  
       <div class="searchBar">
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('searchArea.rollLoseRates') }}</span></div>
           <div>
            <select class="mr-5" v-model="reqData.gameCategory">
               <option :value="''">{{ $t('searchArea.total') }}</option>
               <template v-for="item in gameGroupCodes" :key="item.groupCode">
                 <option :value="item.groupCode">{{ item.groupCodeName }}</option>
               </template>
             </select>
             <select class="mr-5" v-model="reqData.vendorCode">
               <option :value="''">{{ $t('searchArea.total') }}</option>
               <template v-for="item in vendorCodeList" :key="item.code">
                 <option :value="item.code">{{ item.codeName }}</option>
               </template>
             </select>
             <select v-model="reqData.rateTypeCode" v-if="isCode"> <!--미니게임 하이로우88 처럼 하위 항목이 있을 때 생성-->
               <option :value="''">{{ $t('searchArea.total') }}</option>
               <template v-for="item in codeList" :key="item.code">
                 <option :value="item.code">{{ item.codeName }}</option>
               </template>
             </select>
           </div>
         </div>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
        <div class="total-info-wrap"></div>
      </article>
      <div class="table-wrapper">
        <partner-table v-if="outSearchList"
                       :table="'main'"
                       :headInfo="headInfo"
                       :list="outSearchList"
                       :isCode="isCode"
                       :reqData="reqData" :key="reqData.endDate"
                       :level="1" />
      </div>
    </div>
    <pagination v-if="outSearchList" @goPage="loadData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import { getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import { partnerNewSettingList } from '@/api/partner'
import PartnerTable from '@/components/ui/partner/partnerTableSet.vue'
import { getCode } from '@/api/common'
import { mapState } from 'vuex'
import { getGameCodes } from '@/api/member'
export default {
  name: 'partnerList',
  components: {
    PartnerTable,
    Pagination
  },
  computed: {
    ...mapState([
      'gameGroupCodes',
      'siteData'
    ])
  },
  data: function () {
    return {
      modalOn: false,
      toggle: false,
      toggleA: false,
      classes: {
        open: 'fa-minus',
        close: 'fa-plus'
      },
      headInfo: {
        fstColumn: true,
        dataList: ['id', 'nick', 'partnerLenel', 'roll', 'lose', 'subPartnerCnt', 'bottomuser', 'cashAmt', 'pointAmt', 'modify']
      },
      outSearchList: [],
      pageInfo: {
        page: 0,
        count_per_list: 0,
        tatal_list_count: 0
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        siteId: '',
        memId: '',
        partnerLevel: '',
        searchType: 'OUTER',
        startDate: '',
        endDate: '',
        gameCategory: '',
        vendorCode: '',
        rateTypeCode: ''
      },
      dateConfigs: {
        enableTime: false,
        dateFormat: 'Y-m-d'
      },
      tableData: [],
      startDefault: '',
      endDefault: '',
      searchOptionText: {
        option: 'memId',
        text: ''
      },
      vendorCodeList: [],
      codeList: [],
      isCode: false
    }
  },
  watch: {
    'reqData.partnerLevel' () {
      this.outSearchList = []
      this.loadData()
    },
    async 'reqData.gameCategory' () {
      this.outSearchList = []
      this.reqData.vendorCode = ''
      if (this.reqData.gameCategory) {
        await this.getVendorCode()
      }
    },
    async 'reqData.vendorCode' () {
      this.outSearchList = []
      if (this.reqData.vendorCode) {
        await this.getCode()
        if (!this.isCode) {
          await this.loadData(1)
        }
      }
    },
    async 'reqData.rateTypeCode' () {
      this.outSearchList = []
      if (this.reqData.rateTypeCode) {
        await this.loadData(1)
      }
    }
  },
  methods: {
    getVendorCode () {
      console.log('getVendorCode')
      getGameCodes('kplay', this.reqData.gameCategory).then(res => {
        this.vendorCodeList = res
      })
    },
    async getCode () {
      console.log(this.reqData.vendorCode)
      await getCode('kVendorRateType', this.reqData.vendorCode).then(res => {
        console.log('code : ', res)
        this.codeList = res
        this.isCode = this.codeList.length > 0
        console.log(this.isCode)
      })
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
        _date.setHours(0, 0, 0)
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
        _date.setHours(23, 59, 59)
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },

    async loadData (page) {
      this.emitter.emit('Loading', true)
      if (page) {
        this.reqData.page = page
      }

      const reqData = {
        ...this.reqData
      }

      if (this.searchOptionText.text) {
        reqData[this.searchOptionText.option] = this.searchOptionText.text
      }

      if (!this.isCode) {
        delete reqData.rateTypeCode
      }

      const data = await partnerNewSettingList(reqData)
      console.log(reqData)
      console.log(this.outSearchList)
      this.outSearchList = data.data.list

      if (data.data.pageInfo) {
        this.pageInfo = data.data.pageInfo
      }
      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.reqData.siteId = this.siteData.siteId
    this.setStartDate()
    this.setEndDate()
    // await this.loadData()
  }
}
</script>

<style scoped>
.p0 {padding: 0;}
.w70 {width: 70px;}
.btn-stairOpen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonPositionWrap {
  display: flex;
  justify-content: center;
}
.searchBar > * {
  margin-right: 5px;
}
.searchBar > *:last-child {
  margin-right: 0;
}
.memIdRow {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
}
.btn-childOpen {
  position: relative;
}
.memIdRow span {
  position: relative;
  white-space: nowrap;
}
.searchwrapIn{display: flex;}
.moreTable {width: 100%;border: 2px solid #e1534e;}
.moreTable .moreTable {border: 0;}
.moreTable td {background: #fff3f3 !important;}
.moreTable .moreTable td {background: #e8ffe9 !important;}
.moreTable .moreTable .moreTable td {background: #d8eaf5 !important;}
.moreTable .moreTable .moreTable .moreTable td {background: #fffee1 !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable td {background: #e1fffb !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable .moreTable td {background: #ffe1e1 !important;}
.mainTable tbody:last-child .moreTable tr:last-child {border-bottom: 0;}
.morebtn {justify-content: center;display: flex;align-items: center;}
.morebtn a {cursor: pointer;}
.select-btn {background-color: #82a6a4;color: #fff;}
button {cursor: pointer}

.mainTable ul {display: flex;}
.mainTable ul li {width: 100%;height: 40px;background: #353535;font-size: 14px;color: #fff;display: flex;align-items: center;justify-content: center;}
</style>
