<template>
  <div :class="{mainTable: table == 'main', moreTable: table =='more'}">
    <ul v-if="table === 'main'">
     <li class="mw130"><a>{{$t('table.head.id')}}</a></li>
     <li>{{$t('table.head.nick')}}</li>
     <li>{{$t('table.head.partnerLevel')}}</li>
     <li class="mw130p5">{{ $t('common.rolling') }}</li>
     <li class="mw130p5">{{ $t('common.losing') }}</li>
     <li>{{$t('table.head.subPartnerCnt')}}</li>
     <li>{{$t('table.head.haveUserNum')}}</li>
     <li>{{$t('table.head.haveAmt')}}</li>
     <li>{{$t('table.head.pointAmt')}}</li>
     <li>{{$t('table.head.subpartner')}}</li>
     <li>{{$t('table.head.userCreate')}}</li>
     <li>{{$t('table.head.option')}}</li>
    </ul>
    <template v-if="list.length > 0" >
      <partner-tr v-for="item in list"
                  :data="item"
                  :reqData="reqData"
                  :level="level"
                  :isCode="isCode"
                  :key="item.memId"/>
    </template>
  </div>
</template>

<script>
import TableHead from '@/components/main/table/Head.vue'
import { mapState } from 'vuex'
import PartnerTr from '@/components/ui/partner/partnerTrSet.vue'

export default {
  name: 'partnerTable',
  inheritAttrs: false,
  components: {
    PartnerTr,
    TableHead
  },
  props: [
    'table',
    'headInfo',
    'list',
    'reqData',
    'level',
    'isCode'
  ],
  computed: {
    ...mapState([
      'gameGroupList'
    ])
  }
}
</script>

<style scoped>
.p0 {padding: 0;}
.w70 {width: 70px;}
.mw130p5 {min-width: 130px;width: 100%; padding-left: 5px; padding-right: 5px;}
.mw130 {min-width: 130px;width: 100%; padding-left: 20px; padding-right: 13px;}
.btn-stairOpen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonPositionWrap {
  display: flex;
  justify-content: center;
}
.searchBar > * {
  margin-right: 5px;
}
.searchBar > *:last-child {
  margin-right: 0;
}
.memIdRow {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
}
.btn-childOpen {
  position: relative;
}
.memIdRow span {
  position: relative;
  white-space: nowrap;
}
.moreTable {width: 100%;}
.moreTable .moreTable {border: 0;}
.moreTable td {background: #fff3f3 !important;}
.moreTable .moreTable td {background: #e8ffe9 !important;}
.moreTable .moreTable .moreTable td {background: #d8eaf5 !important;}
.moreTable .moreTable .moreTable .moreTable td {background: #fffee1 !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable td {background: #e1fffb !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable .moreTable td {background: #ffe1e1 !important;}

.mainTable tbody:last-child .moreTable tr:last-child {border-bottom: 0;}
.morebtn {justify-content: center;display: flex;align-items: center;}
.morebtn a {cursor: pointer;}

.mainTable ul {display: flex;}
.mainTable ul li {width: 100%;height: 40px;background: #353535;font-size: 14px;color: #fff;display: flex;align-items: center;justify-content: center;}
.mainTable ul li:first-child a {width: 200px;color: #fff;}
</style>
